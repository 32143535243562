import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

import _ from "lodash";

import Screen from "./screen";
import Loading from "../../../Loading/Loading";
import { gateway } from "../../../../utils/api";
import { openCallToAction } from "../../../../business/cta";

function Home({ turma }) {
  const [loadingCourses, setLoadingCourses] = useState(true);
  const [loadingCoursesTrail, setLoadingCoursesTrail] = useState(false);
  const [loadingWarnings, setLoadingWarnings] = useState(true);
  const [loadingTrails, setLoadingTrails] = useState(true);
  const [loadingEvents, setLoadingEvents] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [events, setEvents] = useState([]);
  const [errorCoursesMessage, setErrorCoursesMessage] = useState(null);
  const [errorWarningsMessage, setErrorWarningsMessage] = useState(null);
  const [errorEventsMessage, setErrorEventsMessage] = useState(null);
  const [errorTrailsMessage, setErrorTrailsMessage] = useState(null);
  const [errorCoursesTrailMessage, setErrorCoursesTrailMessage] = useState(
    null
  );
  const [selectedItem, setSelectedItem] = useState(null);

  const [trail, setTrail] = useState(null);
  const [responseCoursesData, setResponseCoursesData] = useState(null);
  const [responseCoursesTrailData, setResponseCoursesTrailData] = useState(
    null
  );
  const [responseWarningsData, setResponseWarningsData] = useState(null);
  const [responseTrailsData, setResponseTrailsData] = useState(null);

  async function loadCourses(code) {
    try {
      setLoadingCourses(true);
      const url = `webbff/lit_app_web/classes/${code}/enrollment/content`;
      const response = await gateway.get(url);
      setResponseCoursesData(response);
    } catch (error) {
      console.log(error);
      setErrorCoursesMessage("Erro ao buscar cursos");
    } finally {
      setLoadingCourses(false);
    }
  }

  async function loadTrailCourses(trailCode) {
    try {
      setLoadingCoursesTrail(true);
      const url = `webbff/lit_app_web/classes/${trailCode}/enrollment/content?fatherType=trail`;
      const response = await gateway.get(url);
      setResponseCoursesTrailData(response);
    } catch (error) {
      console.log(error);
      setErrorCoursesTrailMessage("Erro ao buscar cursos da trilha");
    } finally {
      setLoadingCoursesTrail(false);
    }
  }

  async function loadTrails(code) {
    try {
      setLoadingTrails(true);
      const url = `webbff/lit_app_web/classes/${code}/enrollment/content?childType=trail`;
      const response = await gateway.get(url);

      setResponseTrailsData(response);
    } catch (error) {
      console.log(error);
      setErrorTrailsMessage("Erro ao buscar trilhas");
    } finally {
      setLoadingTrails(false);
    }
  }
  

  async function loadWarnings(code) {
    try {
      setLoadingWarnings(true);
      const url = `webbff/lit_app_web/classes/${code}/warnings`;
      const response = await gateway.get(url);
      return response;
    } catch (error) {
      console.log(error);
      setErrorWarningsMessage("Erro ao buscar avisos");
    } finally {
      setLoadingWarnings(false);
    }
  }

  async function loadWarningsAll(code) {
    const [warnings] = await Promise.all([
      loadWarnings(code),
    ])
    const docs = _.get(warnings, 'docs', []);
    const warningsWall = {...warnings, docs, totalDocs: docs.length}
    setResponseWarningsData(warningsWall);
  }

  async function loadEvents(code) {
    try {
      setLoadingEvents(true);
      const url = `webbff/lit_app_web/event/class`;
      const body = {
        classCode: code
      };
      const response = await gateway.post(url, body);
      setEvents(response);
    } catch (error) {
      console.log(error);
      setErrorEventsMessage("Erro ao buscar eventos");
    } finally {
      setLoadingEvents(false);
    }
  }

  const handleOpenModal = useCallback(item => {
    setModalIsOpen(true);
    setSelectedItem(item);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalIsOpen(false);
    setSelectedItem(null);
  }, []);

  const handleCallToAction = useCallback(cta => {
    openCallToAction(cta);
  }, []);

  const handleSelectTrail = useCallback(
    item => {
      if (trail && trail.id === item.id) {
        setTrail(null);
        setResponseCoursesTrailData(null);
      } else {
        console.log("else");
        setTrail(item);
      }
    },
    [trail]
  );

  useEffect(() => {
    if (trail) {
      const trailCode = _.get(trail, "code", null);
      loadTrailCourses(trailCode);
    }
  }, [trail]);

  useEffect(() => {
    if (turma) {
      loadCourses(turma.contentCode);
      loadTrails(turma.contentCode);
      loadWarningsAll(turma.organizationCode);
      loadEvents(turma.organizationCode);
    }
  }, [turma]);

  return (
    <>
      {(loadingCourses ||
        loadingWarnings ||
        loadingEvents ||
        loadingTrails) && <Loading img width={30} height={30} />}
      {!loadingCourses && (
        <Screen
          turma={turma}
          modalIsOpen={modalIsOpen}
          responseCoursesData={responseCoursesData}
          responseWarningsData={responseWarningsData}
          responseTrailsData={responseTrailsData}
          responseCoursesTrailData={responseCoursesTrailData}
          trail={trail}
          events={events}
          errorCoursesMessage={errorCoursesMessage}
          errorWarningsMessage={errorWarningsMessage}
          errorEventsMessage={errorEventsMessage}
          errorTrailsMessage={errorTrailsMessage}
          errorCoursesTrailMessage={errorCoursesTrailMessage}
          loadingCoursesTrail={loadingCoursesTrail}
          openModal={handleOpenModal}
          selectTrail={handleSelectTrail}
          closeModal={handleCloseModal}
          openCallToAction={handleCallToAction}
          selectedItem={selectedItem}
        />
      )}
    </>
  );
}

Home.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  turma: PropTypes.object.isRequired
};

export default Home;
