import React from 'react';
import _ from 'lodash'
import * as Styles from './styles';
import Loading from "../../../Loading/Loading";

function Screen({headerTableTittles = ['Código', 'Disciplina', 'Média Final', 'Faltas', 'Situação'], contentTable, isLoading = false}) {
    const settings = JSON.parse(localStorage.getItem('settings'));
    const tenant = localStorage.getItem('tenant') || '';
  
    const { headerBackgroundColor  } = _.get(
        settings,
        `${tenant}`,
        {}
      );
  
    const renderBackgroundNav = headerBackgroundColor || '#27AAE1';
    const getStatus = (status) => {
        if (status === 'Aprovado') return '#27AAE1' 
        return '#F44336' 
    };

    if (isLoading) {
        return (
        <Styles.MessageWrapper>
            <Loading img width={30} height={30} />
        </Styles.MessageWrapper>
        )
    }

    if (contentTable.length === 0) {
        return <Styles.MessageWrapper>Nenhum dado disponível.</Styles.MessageWrapper>;
    }
      
  return (
    <Styles.TableWrapper>
      <Styles.TableTitle>Notas e Faltas</Styles.TableTitle>
      <Styles.StyledTable>
        <thead>
          <tr>
            {headerTableTittles.map((item, index) => <Styles.StyledTh backgroundColor={renderBackgroundNav} key={index} style={{ textAlign: 'center' }}>{item}</Styles.StyledTh>)}
          </tr>
        </thead>
        <tbody>
        {contentTable.map((item, index) => {
                return (
                    <Styles.StyledTr key={index}>
                        <Styles.StyledTd style={{ textAlign: 'center' }}>{item.IDTURMADISC || "-"}</Styles.StyledTd>
                        <Styles.StyledTd>{item.DISCIPLINA || "-"}</Styles.StyledTd>
                        <Styles.StyledTd style={{ textAlign: 'center' }}>{item.NOTA || "-"}</Styles.StyledTd>
                        <Styles.StyledTd style={{ textAlign: 'center' }}>{item.FALTAS || "-"}</Styles.StyledTd>
                        <Styles.StyledTd style={{ backgroundColor: getStatus(item.STATUS_DISCIPLINA || item.STATUS_RESULTADO_DISCIPLINA), color: 'white', textAlign: 'center' }}>{item.STATUS_DISCIPLINA}</Styles.StyledTd>
                    </Styles.StyledTr>
                );
            })}
        </tbody>
      </Styles.StyledTable>
    </Styles.TableWrapper>
  );
}




export default Screen;
