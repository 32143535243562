/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
import React, { useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import Loading from "../../../Loading/Loading";

import ContentCourseModal from "./components/ContentCourseModal";
import Modal from "../../../LitAtomicComponents/Modals/GenericModal";
import LaneCourses from "./components/LaneCourses";
import LaneEvents from "./components/LaneEvents";
import LaneWarnings from "./components/LaneWarnings";
import LaneTrails from "./components/LaneTrails";
import LaneCoursesTrail from "./components/LaneCoursesTrail";

import imageDefault from "../../../../images/default-image.png";

import ModalWarningView from "../../../Modals/ModalWarningView";

import * as Styles from "./styles";

import { TYPES } from "./constants";

function Screen({
  turma,
  trail,
  responseCoursesData,
  responseWarningsData,
  responseTrailsData,
  responseCoursesTrailData,
  events = [],

  loadingCoursesTrail,

  modalIsOpen,
  openModal,
  selectTrail,
  closeModal,
  openCallToAction,
  selectedItem,
  errorCoursesMessage,
  errorWarningsMessage,
  errorEventsMessage,
  errorTrailsMessage,
  errorCoursesTrailMessage
}) {

  // control modal
  const [element, setElement] = useState({})
  const [openModalWarn, setOpenModalWarn] = useState(false)
  const onOpenModalWarn = () => setOpenModalWarn(true)
  const onCloseModalWarn = () => setOpenModalWarn(false);

  const renderModal = useMemo(() => {
    const type = _.get(selectedItem, "type", "");
    switch (type) {
      case TYPES.COURSE: {
        const course = _.get(selectedItem, "item");
        const img = _.get(course, "media.path", imageDefault);
        return (
          <Modal
            isOpen={modalIsOpen}
            closeModal={closeModal}
            pageImage={img}
            textHeader={course.name}
          >
            <ContentCourseModal course={course} turma={turma} />
          </Modal>
        );
      }

      default:
        return null;
    }
  }, [selectedItem]);

  const handleCallToAction = useCallback(cta => {
    const keys = Object.keys(cta);
    const values = Object.values(cta);
    const obj = {
      key: keys[0],
      value: values[0]
    };
    onOpenModalWarn();
    openCallToAction(obj);
  }, []);

  const openModalWarning = el => {
    setElement(el);
    onOpenModalWarn();
  }

  return (
    <Styles.Container>
      <LaneWarnings
        data={responseWarningsData}
        errorWarningsMessage={errorWarningsMessage}
        organizationCode={turma.organizationCode}
        onClick={(el) => openModalWarning(el)} 
      />

      <LaneEvents
        data={events}
        errorEventsMessage={errorEventsMessage}
        onClick={el => openCallToAction({
          ...el.cta,
          courseCrCode: el.courseCodes[0] || ''}
        )}
      />

      <LaneCourses
        onClick={el =>
          openModal({
            type: TYPES.COURSE,
            item: el
          })
        }
        data={responseCoursesData}
        contentCode={turma.contentCode}
        errorCoursesMessage={errorCoursesMessage}
      />

      <LaneTrails
        data={responseTrailsData}
        trail={trail}
        errorTrailsMessage={errorTrailsMessage}
        contentCode={turma.contentCode}
        onClick={selectTrail}
      />

      {loadingCoursesTrail && (
        <Styles.ContentLoadingCoursesTrail>
          <Loading
            width={30}
            height={30}
            message="Carregando disciplinas da trilha"
          />
        </Styles.ContentLoadingCoursesTrail>
      )}

      {responseCoursesTrailData && (
        <LaneCoursesTrail
          data={responseCoursesTrailData}
          errorCoursesTrailMessage={errorCoursesTrailMessage}
          trail={trail}
          onClick={el =>
            openModal({
              type: TYPES.COURSE,
              item: el
            })
          }
        />
      )}

      {renderModal}

      <ModalWarningView 
        openModal={openModalWarn}
        onCloseModal={onCloseModalWarn}
        onClick={handleCallToAction}
        element={element}
        onOrganization
        />
    </Styles.Container>
  );
}

Screen.propTypes = {
  courses: PropTypes.array.isRequired,
  turma: PropTypes.object.isRequired,
  warnings: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  modalIsOpen: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedItem: PropTypes.object,
  errorCoursesMessage: PropTypes.string.isRequired,
  errorWarningsMessage: PropTypes.string.isRequired,
  errorEventsMessage: PropTypes.string.isRequired
};

export default Screen;
