import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Screen from './screen';
import { gateway } from '../../../../utils/api';


function Grades({ turma }) {
  const [loading, setLoading] = useState(true);
  const [contentTable, setContentTable] = useState([])

  console.log(turma)

  const fetchStudentGrades = useCallback(async () => {
    try {
      const classCode = turma.contentCode
      const email = localStorage.getItem("ra")
      console.log(email)
      const url = `person/person/getStudentGradeAndAbsences?email=${email}&classCode=${classCode}`;
      const courses = await gateway.get(url);
      setContentTable(courses)
      console.log('Dados carregados da API:', courses);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [turma]);

  useEffect(() => {
    fetchStudentGrades();
  }, [fetchStudentGrades]);

  console.log(turma);

  return (
    <>
      <Screen contentTable={contentTable} isLoading={loading}/>
    </>
  );
}

Grades.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  turma: PropTypes.object.isRequired,
};

export default Grades;
