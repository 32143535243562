/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import Lane from '../../../../../shared/Lane';
import CardCourse from '../CardCurso';
import * as Styles from './styles';

function Screen({ trails, trail: selectedTrail, getMoreTrails, onClick, errorTrailsMessage }) {
  if (!trails || trails.length === 0) return null;
  return (
    <Styles.Container>
      <Lane title="Trilhas da turma" paginationHook={getMoreTrails}>
        {!trails.length && (
          <Styles.EmptyListText>
            {errorTrailsMessage || 'Sem trilhas'}
          </Styles.EmptyListText>
        )}
        {trails.map(trail => {
          return (
            <Styles.Wrapper key={`${trail.id}`}>
              <CardCourse
                selected={trail.id === selectedTrail.id} 
                course={trail} 
                onClick={(trail) => onClick(trail)}
              />
            </Styles.Wrapper>
          );
        })}
      </Lane>
    </Styles.Container>
  );
}

export default Screen;
